const QUERIES = {
  USERS_LIST: 'users-list',
  STAKEHOLDERS_LIST: 'stakeholder-list',
  ALL_AUTHORITY_LIST: 'auth-stakeholder-list',
  AUTHORITY_LIST: 'auth-list',
  ALL_VENDORS: 'vendor-stakeholder-list',
  PARAMETER_LIST: 'parameter-list',
  SITE_LIST_LOT_ADD: 'site-list-lot-add',
  SITE_LIST_LOT_VIEW: 'site-list-lot-view',
  SITE_LIST: 'site-list',
  SITE_COUNT: 'site-count',
  SITE_BY_CITY: 'site-by-city',
  CITIES:'all-cities',
  SITE_TYPE_LIST: 'site-type-list',
  FILE_LIST: 'file-list',
  FILE_TYPE_LIST: 'file-type-list',
  FILE_LOG_LIST: 'file-log-list',
  CONTRACT_LIST: 'contract-list',
  LOT_LIST: 'lot-list',
  CITY_LIST: 'city-list',
  DISTRICT_LIST: 'district-list',
  USERS_LIST_CEB: "users-list-ceb",
  USERS_LIST_AUTHORITY: "users-list-authority",
  USERS_LIST_VENDOR: "users-list-vendor",
  NUMBER_OF_DOCTOR_LIST: "number-of-doctor-list",
  LAND_STATUS_LIST: "land-status-list",
  PROJECT_TYPE_LIST: "project-type-list",
  READINESS_OF_LAND_LIST: "readinees-of-land-list",
  LAND_TYPE_LIST: "land-type-list",
  PROCUREMENT_METHOD_LIST: "procurement-method-list",
  PROCUREMENT_STATUS_LIST: "procurement-status-list",
  PROCUREMENT_PLAN_LIST: "procurement-plan-list",
  PROCUREMENT_FILE_LIST: "procurement-file-list",
  PROCUREMENT_LIST: "procurement-list",
  PERMISSION_LIST: "permission-list",
  FILE_CONTROL_LIST: "file-control-list",
  PROGRESS_MANAGEMENT_LIST: "progress-management-list",
  PROGRESS_MANAGEMENT_LISTS: "progress-management-lists",
  MAF_MANAGEMENT_LIST: "mf-management-list",
  RFI_MANAGEMENT_LIST: "rfi-management-list",
  NCR_MANAGEMENT_LIST: "ncr-management-list",
  VO_MANAGEMENT_LIST: "vo-management-list",
  LETTHER_MANAGEMENT_LIST: "vo-management-list",
  KPI_SITE_INFO: "kpi-site-info",
  BUG_REPORTING_LIST: "bug-reporting",
  CONTRACT_BY_SITES: "contractBySites"
}

export { QUERIES }
const FILETYPES = {
  DEMOLITION_APPROVAL: { id: "027cb69d-8ee6-415a-beb7-93e3f2dc13e3", name: "Demolotion_Approval_Document" },
  GEOLOGICAL_SURVEY: { id: "685364dc-c00c-4425-b8d6-ff74ff3244ad", name: "Geological_Survey_Document" },
  TOPOGRAPHICAL_SURVEY_PLAN: { id: "c3a0c110-cad2-4770-b7ee-9f45710c8520", name: "Topographical_Survey_Document" },
  TITLE_DEED: { id: "6b2876ee-ce39-4ffe-bb48-a4ea48b4a0d2", name: "Title_Deed_Document" },
  ZONING_DIAMETER_CERTIFICATE_WITH_COORDINATES: { id: "d87e71fc-039b-4668-8108-289b4868ab9c", name: "Zoning_Diamater_Certificate_Document" },
  ZONING_STATUS_CERTIFICATE_WITH_COORDINATES: { id: "ba583df9-16c0-4882-b6ba-aae276d908da", name: "Zoning_Status_Certificate_Document" },
  SURROUNDING_ROAD_LEVELS: { id: "8064ec89-945c-4c52-8134-166083dec5e7", name: "Surrounding_Road_Level_Document" },
  ZONING_PLAN_SCALE_5000: { id: "3fbda7c8-c950-4469-96bc-1c7620650f24", name: "Zoning_Plan_Scale_1-5000_Document" },
  ZONING_PLAN_SCALE_1000: { id: "8adc5f53-3030-40fe-86a9-74e2f397fa27", name: "Zoning_Plan_Scale_1-1000_Document" },
  CADASTRAL_DOCUMENT_WITH_COORDINATES: { id: "a003c355-f751-4b1f-835a-70d7e130f0b9", name: "Cadastral_Document" },
  UP_TO_DATE_CERTIFICATE_OF_ALLOCATION: { id: "4344be48-c06d-4859-bff0-5a479f1f65cc", name: "UpToDate_CertificateOf_Allocation_Document" },
  CONSTRUCTION_PERMIT: { id: "a6c17219-cce0-4d29-b18f-b69f4fd9439f", name: "Construction_Permit_Document" },
  OCCUPANCY_PERMIT: { id: "8f1289ec-7355-4993-908c-337124cc66ac", name: "Occupancy_Permit_Document" },
  ELECTRICITY: { id: "df116ee3-e19c-4e0f-86d5-ee6c806be183", name: "Electricity_Document" },
  // WATER_SUPPLY: { id: "2b929aa5-37c5-417c-9a05-c6b39354332e", name: "Water_Supply_Document" },
  // WASTE_WATER: { id: "eead7810-de71-41b1-af4e-aa34bb7517af", name: "Waste_Water_Document" },
  // RAIN_WATER: { id: "fe4cb5c0-0d10-47bc-b853-eb4658743b0b", name: "Rain_Water_Document" },
  //WATER_DOCS: { id: "f83befa1-b064-45bf-80f2-590037672f5a", name: "Water_Documents" },
  WATER_DOCS: { id: "2b929aa5-37c5-417c-9a05-c6b39354332e", name: "Water_Documents" },
  NATURAL_GAS: { id: "596f7591-50ed-447a-b6ba-5b80fb8a5b3e", name: "Natural_Gas_Document" },
  TELECOMMUNICATION: { id: "232a385b-1ff8-4e39-9b66-a69eedca27d9", name: "Telecommunication_Document" },
  FIRE_PROTECTION: { id: "00264d7a-f7d6-4737-969a-72d5ea4cedf8", name: "Fire_Protection_Document" },
  ARCHITECTURAL: { id: "30a7916b-a518-493c-88ea-f13bdcbdf17d", name: "Architectural_Documnet" },
  STRUCTURAL: { id: "5659b46a-ea8c-4d04-b018-e403f07db4a2", name: "Structural_Documnet" },
  MECHANICAL: { id: "4acac90a-026f-412d-bbba-eb3ebab5b926", name: "Mechanical_Document" },
  ELECTRICICAL: { id: "d1d1e8f9-7f23-4323-9003-53ad3ac42429", name: "Electirical_Document" },
  TECHNICAL_SPECIFICATION: { id: "11835354-b1cb-40f9-ba98-a613a5189dc9", name: "TechnicalSpecification_Document" },
  BOQ: { id: "c7fd07c9-110b-4e46-82e3-f312b9be8032", name: "BoQ_Document" },
  PHOTOS: { id: "e5af486c-721b-4acb-901f-b5afdc07dc9c", name: "Photo_Before_Construction" },
  SUSTAINABILITY: { id: "d4749147-09b9-4d58-958f-22c5bf6d1a4f", name: "Sustainability_Document" },
  RFI: { id: "8f33c709-2361-4b66-90b7-f132d92bb34b", name: "Sustainability_Document" },
  NCR: { id: "a72d4557-89d4-4924-9013-47216053c709", name: "Sustainability_Document" },
  MAF: { id: "4f7c0f98-c942-45d0-929d-5b08fab91dea", name: "Sustainability_Document" },
  CR: { id: "3deb73dc-76ac-4e0d-92df-9d8f650a2a90", name: "Sustainability_Document" },
  SURVEY_STUDIES:{id: "85f1d48f-5c74-4354-9269-987c11b2dd37",name:"Survey_Studies"},
  BUG_REPORTING:{id: "5f9f8ee3-b411-4c9e-8c64-a4fe32e99bda",name:"Bug_Reporting"},
  LETTER:{id: "4eeb5a4b-033e-4bd9-9e9f-dd31210f192f", name:"Letters_Communication"},
  OTHER:{id: "7e041875-2b3c-4f34-bc52-8f2f2cdaa12b", name:"Other"},
  MEETING_NOTES:{id: "36057928-efbe-4271-aabf-48838fffb9d8", name:"Meeting_Notes"},
  VARIATION_ORDER:{id: "dd5273e3-9fa9-4dd5-a0e6-724ec035ed14", name:"Variation_Order"},
  PP:{id: "4d54ebab-ff29-4672-9b9d-ef0c5ab1948e", name:"Proggress_Payment"},
  COMP:{id: "83c1f9c5-6ab1-4917-ac21-3cd99a52a3f6", name:"Completion"},
  ABD:{id: "1d7ef051-2073-43ee-be78-f1e56b30b148", name:"ABD"},
  BH:{id: "62d3a216-6ff1-4303-b037-aa324e253a92", name:"BHD"},
  DNP:{id: "6e4756b7-f4e8-4e9d-bc22-e40b28ce75d8", name:"DNP"},
  LEGAL:{id: "da0d3f2d-0e66-4e6b-a395-7307b42958e1", name:"LEGAL"},
  FACILITY:{id: "2e3f4fc8-25e8-4218-a293-6d74a22031d9", name:"FACILITY"},

}
export { FILETYPES }


const SITETYPES = {
  MHC: { id: "69a24f46-f098-4c4f-81a0-ab73fc424f84", name: "MHC" },
  EMHC: { id: "46ab576e-c334-41c7-8e1e-c00c58a55bf7", name: "E-MHC" },
  PTR: { id: "be28736a-fd36-4e02-9335-577871301035", name: "PTR" },
  HOSPITAL: { id: "a6ca1f28-a70e-449e-a17e-ab1f2b1dc2ca", name: "Hospital Rehabilitation" },
  MEDICALDEVICES_AND_EQUIPMENT: { id: "1b1479aa-9620-43be-aa4b-913ff7c7977a", name: "Purchasing Medical Devices and Equipment" },
  BIRTH_AND_HYGIENEKIT: { id: "650ee2ba-acdc-4585-9b6b-4e5d9d60fca8", name: "Purchasing Birth and Hygiene Kit" },
}

const ROLES = {
  Superadmin: { name: "Superadmin"},
  CEB: { name: "CEB"},
  MOH_PIU: { name: "MOH_PIU"},
  MOH_Directorate: { name: "MOH_Directorate"},
  MOH_HGG: { name: "MOH_HGG"},
  SYGM_EPED: { name: "SYGM_EPED"},
  SYGM_ID: { name: "SYGM_ID"},
  SYGM_BR: { name: "SYGM_BR"},
  KHGM_IB: { name: "KHGM_IB"},
  HSGM_IB: { name: "HSGM_IB"},
  HSGM_GS: { name: "HSGM_GS"},
  MOH_PD: { name: "MOH_PD"},
  MOH_Procurement: { name: "MOH_Procurement"},
  TUMAS_TeamLeader: { name: "TUMAS_TeamLeader"},
  TUMAS_RegionalDirector: { name: "TUMAS_RegionalDirector"},
  TUMAS_Design: { name: "TUMAS_Design"},
  TUMAS_Supervisor: { name: "TUMAS_Supervisor"},
  SUMAF_Team: { name: "SUMAF_Team"},
  EUD: { name: "EUD"},
  GOPA: { name: "GOPA"}
}
export { SITETYPES, ROLES }

const DELAY = {
  NUMBER: 1500
}
export { DELAY }

const CONTRACTSTATUS = {
  MAJORID: "f0da8150-3323-4ee1-9be5-6f5d8a85890d"
}
export { CONTRACTSTATUS }

const FILEGROUP = {
  NONE:0,
  MAIN: 1,
  ATTACHMENT: 2
}
export { FILEGROUP }