import axios, { AxiosResponse } from 'axios'
import { ProcurementModel, LotQueryResponse, SiteModel, CityModel, ProcurementQueryResponse, LotModel } from '../../core/_models'
import { ID, Response } from "../../../../../_metronic/helpers"

const API_URL = "https://shifatr.org/api/procurement"
const API_LOT_URL = "https://shifatr.org/api/procurement-lot"

const getProcurements = async (query: string): Promise<ProcurementQueryResponse | undefined> => {
  return await axios
    .get(`${API_URL}?${query}`)
    .then((response: Response<ProcurementQueryResponse>) => response.data)
}

const getProcurementsForDashboard = async (query: string): Promise<ProcurementModel[] | undefined> => {
  return await axios
    .get(`${API_URL}/forDashboard?${query}`)
    .then((response: Response<ProcurementModel[]>) => response.data)
}

const createProcurement = (contract: ProcurementModel): Promise<ProcurementModel | undefined> => {
  return axios
    .post(API_URL, contract)
    .then((response: AxiosResponse<Response<ProcurementModel>>) => response.data)
    .then((response: Response<ProcurementModel>) => response.data)
}
const updateProcurement = (contract: ProcurementModel): Promise<ProcurementModel | undefined> => {
  return axios
    .put(`${API_URL}/${contract.id}`, contract)
  // .then((response: AxiosResponse<Response<ParameterModel>>) => response.data)
  // .then((response: Response<ParameterModel>) => response.data)
}

const deleteParameter = (parameterId: ID): Promise<void> => {
  return axios.delete(`${API_URL}/${parameterId}`).then(() => { })
}


const getLotsByProcurementId = async (query: string): Promise<LotQueryResponse | undefined> => {
  return await axios
    .get(`${API_LOT_URL}?${query}`)
    .then((response: Response<LotQueryResponse>) => response.data)

}


const getContractById = (id: ID): Promise<ProcurementModel | undefined> => {
  return axios
    .get(`${API_URL}/${id}`)
    .then((response: Response<ProcurementModel>) => response.data)
}

const getSitesByCityId = (id: ID): Promise<Array<SiteModel>> | any => {
  return axios
    .get(`${API_URL}/getsite/${id}`)
    .then((response: Response<Array<SiteModel>>) => response.data)
}

const getCities = (): Promise<Array<CityModel>> | any => {
  return axios
    .get(`${API_URL}/getCities`)
    .then((response: Response<Array<CityModel>>) => response.data)
}

const createLot = (lot: LotModel): Promise<LotModel | undefined> => {
  return axios
    .post(API_LOT_URL, lot)
    .then((response: Response<LotModel>) => response.data)
}

const deleteLot = (lotId: ID): Promise<void> => {
  return axios.delete(`${API_URL}/${lotId}`).then(() => { })
}

const deleteContract = (contractId: ID): Promise<void> => {
  return axios.delete(`${API_URL}/${contractId}`).then(() => { })
}



const deleteSite = (siteId: ID): Promise<void> => {
  return axios.delete(`${API_URL}/${siteId}`).then(() => { })
}

const getHelpPdf = (): any => {
  return axios
    .get(`${API_URL}/getHelpDocument`, {responseType: 'blob'})
    .then((d: any) => d.data)
}

export { getProcurementsForDashboard, getProcurements, getCities, getSitesByCityId, deleteContract, deleteLot, deleteSite, createLot, getLotsByProcurementId, getContractById, createProcurement, updateProcurement, deleteParameter, getHelpPdf }