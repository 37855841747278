import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { getIsServiceVendor, getIsUserAdmin, getUserRole, ROLES, WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import BugReportingPage from 'app/modules/bug-reporting/BugReportingPage'
import LogFramePage from 'app/modules/log-frame/LogFrame'
import EstimatedCostPage from 'app/modules/estimated-cost/EstimatedCost'
import RiskMatrixPage from 'app/modules/risk-matrix/RiskMatrix'
import CommunicationStrategyPage from 'app/modules/communication-strategy/CommunicationStrategy'
import PrintedMaterialsPage from 'app/modules/printed-materials/PrintedMaterials'
import OnlineToolsPage from 'app/modules/online-tools/OnlineTools'
import Audio_VisualToolsPage from 'app/modules/audio_visual-tools/Audio_VisualTools'
import InformationCampaignPage from 'app/modules/information-campaign/InformationCampaign'
import VisibilityEventPage from 'app/modules/visibility-event/VisibilityEvent'
import Contest_SiteEventPage from 'app/modules/contest_site-event/Contest_SiteEvent'
import ProgressPaymentBarchartPage from 'app/modules/progress-payment-management/ProgressPaymentBarchartPage'
import ShifaImplementationPage from 'app/modules/shifa-implementation/ShifaImplementationPage'
import FinancialOverviewPage from 'app/modules/financal-overview/FinancalOverviewPage'




const PrivateRoutes = () => {
  const role = getUserRole();
  const isAdmin = getIsUserAdmin() === "True";
  const isServiceVendor = getIsServiceVendor() === "True";
  const hasRoleSuperadmin = role === "Superadmin";
  const hasRoleCEB = role === "CEB";
  const hasRoleMOH_PIU = role === "MOH_PIU";
  const hasRoleMOH_Directorate = role === "MOH_Directorate";
  const hasRoleMOH_HGG = role === "MOH_HGG";
  const hasRoleSYGM_EPED = role === "SYGM_EPED";
  const hasRoleSYGM_ID = role === "SYGM_ID";
  const hasRoleSYGM_BR = role === "SYGM_BR";
  const hasRoleKHGM_IB = role === "KHGM_IB";
  const hasRoleHSGM_IB = role === "HSGM_IB";
  const hasRoleHSGM_GS = role === "HSGM_GS";
  const hasRoleMOH_PD = role === "MOH_PD";
  // const hasRoleMOH_Procurement = role === "MOH_Procurement";
  const hasRoleTUMAS_TeamLeader = role === "TUMAS_TeamLeader";
  const hasRoleTUMAS_RegionalDirector = role === "TUMAS_RegionalDirector";
  const hasRoleTUMAS_Design = role === "TUMAS_Design";
  const hasRoleTUMAS_Supervisor = role === "TUMAS_Supervisor";
  const hasRoleSUMAF_Team = role === "SUMAF_Team";
  const hasRoleEUD = role === "EUD";

  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const StakeholdersPage = lazy(() => import('../modules/stakeholders-management/StakeholdersPage'))
  const UsersPageCEB = lazy(() => import("../modules/user-management/user-management-ceb/UsersPage"))
  const ParametersPage = lazy(() => import('../modules/parameter-management/ParametersPage'))
  const SitesPageEMHC = lazy(() => import('../modules/site-management/SitesPageEMHC'))
  const SitesPageMHC = lazy(() => import('../modules/site-management/SitesPageMHC'))
  const SitesPagePTR = lazy(() => import('../modules/site-management/SitesPagePTR'))
  const SitesPageHospital = lazy(() => import('../modules/site-management/SitesPageHospital'))
  const ChecklistPage = lazy(() => import('../modules/mhc-checklist-management/ChecklistPage'))
  const FileUploadPage = lazy(() => import('../modules/file-upload-page/FileUploadPage'))
  const ChecklistMonitoringPage = lazy(() => import('../modules/mhc-checklist-monitoring/ChecklistMonitoringPage'))
  const ProgressPaymentPage = lazy(() => import('../modules/progress-payment-management/ProgressPaymentPage'))
  const ProgressPaymentPageForSS = lazy(() => import('../modules/progress-payment-management-ss/ProgressPaymentPage'))
  const PTRListPage = lazy(() => import('../modules/ptr-list-monitoring/PTRListMonitoringPage'))
  const EMHCListPage = lazy(() => import('../modules/emhc-list-monitoring/EMHCListMonitoringPage'))
  const RehabListPage = lazy(() => import('../modules/rehab-list-monitoring/RehabListMonitoringPage'))
  const ProcurementPage = lazy(() => import('../modules/procurement-management/ProcurementPage'))
  const ProcurementPlan = lazy(() => import('../modules/procurement-plan/ProcurementPlan'))
  const FileControl = lazy(() => import('../modules/file-control-management/FileControlsPage'))
  const RFIsPage = lazy(() => import('../modules/rfi-management/RFIsPage'))
  const NCRsPage = lazy(() => import('../modules/ncr-management/NCRsPage'))
  const MAFsPage = lazy(() => import('../modules/maf-management/MAFsPage'))
  const LettersPage = lazy(() => import('../modules/letter-management/LettersPage'))
  const MNsPage = lazy(() => import('../modules/meeting-note-management/MNsPage'))
  const PtrManagementPage = lazy(() => import('../modules/ptr-checklist-management/ChecklistPage'))
  const RehabHospitalManagementPage = lazy(() => import('../modules/rehab-list-management/ChecklistPage'))
  const EMHCManagementPage = lazy(() => import('../modules/emhc-list-management/ChecklistPage'))
  const OthersPage = lazy(() => import('../modules/other-doc-management/OthersPage'))
  const VariationOrdersPage = lazy(() => import('../modules/variation-order-management/VariationOrdersPage'))
  const ConstructionReportsPage = lazy(() => import('../modules/construction-reports-management/ConstructionReportsPage'))
  const CompletionReportsPage = lazy(() => import('../modules/completion-reports-management/CompletionReportsPage'))
  const BuildingHandoverReportsPage = lazy(() => import('../modules/building-handover-reports-management/BuildingHandoverReportsPage'))
  const DNPReportsPage = lazy(() => import('../modules/dnp-reports-management/DNPReportsPage'))
  const LegalReportsPage = lazy(() => import('../modules/legal-reports-management/LegalReportsPage'))
  const AbdReportsPage = lazy(() => import('../modules/abd-reports-management/AbdReportsPage'))
  const FacilityReportsPage = lazy(() => import('../modules/facility-reports-management/FacilityReportsPage'))

  const MapPage = lazy(() => import('../modules/maps/MapPage'))
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}

        <Route path='dashboard' element={<DashboardWrapper />} />

        {(hasRoleSuperadmin || hasRoleCEB || hasRoleMOH_PIU || hasRoleMOH_Directorate || hasRoleMOH_HGG || hasRoleSYGM_EPED || hasRoleSYGM_BR || hasRoleSYGM_ID || hasRoleKHGM_IB || hasRoleHSGM_IB || hasRoleHSGM_GS
        || hasRoleSUMAF_Team || hasRoleEUD) &&
          <Route
            path='/stakeholders-management/*'
            element={
              <SuspensedView>
                <StakeholdersPage />
              </SuspensedView>
            }
          />
        }

        <Route
          path='/map-management/*'
          element={
            <SuspensedView>
              <MapPage />
            </SuspensedView>
          }
        />


        {(hasRoleSuperadmin || hasRoleMOH_PIU || hasRoleEUD || hasRoleSUMAF_Team) &&
          <Route
            path='/parameter-management/*'
            element={
              <SuspensedView>
                <ParametersPage />
              </SuspensedView>
            }
          />
        }

        {(hasRoleSuperadmin || hasRoleCEB|| hasRoleEUD || hasRoleMOH_PIU /*|| hasRoleMOH_HGG || hasRoleSYGM_EPED || hasRoleSYGM_BR || hasRoleSYGM_ID || hasRoleKHGM_IB || hasRoleHSGM_IB || hasRoleHSGM_GS
        || hasRoleSUMAF_Team */) &&
          <Route
            path='/procurement-plan/*'
            element={
              <SuspensedView>
                <ProcurementPlan />
              </SuspensedView>
            }
          />
        }

        {(hasRoleSuperadmin || hasRoleCEB || hasRoleMOH_PIU || hasRoleMOH_Directorate || hasRoleMOH_HGG || hasRoleSYGM_EPED || hasRoleSYGM_BR || hasRoleSYGM_ID || 
          hasRoleMOH_PD || hasRoleTUMAS_TeamLeader || hasRoleTUMAS_RegionalDirector || hasRoleTUMAS_Design
          || hasRoleSUMAF_Team || hasRoleEUD) &&
          <Route
            path='/site-management/mhc/*'
            element={
              <SuspensedView>
                <SitesPageMHC />
              </SuspensedView>
            }
          />
        }

        {(hasRoleSuperadmin || hasRoleCEB || hasRoleMOH_PIU || hasRoleMOH_Directorate || hasRoleMOH_HGG || hasRoleSYGM_EPED || hasRoleSYGM_BR || hasRoleSYGM_ID || 
          hasRoleMOH_PD || hasRoleTUMAS_TeamLeader || hasRoleTUMAS_RegionalDirector || hasRoleTUMAS_Design
          || hasRoleSUMAF_Team || hasRoleEUD) &&
          <Route
            path='/site-management/emhc/*'
            element={
              <SuspensedView>
                <SitesPageEMHC />
              </SuspensedView>
            }
          />
        }

        {(hasRoleSuperadmin || hasRoleCEB || hasRoleMOH_PIU || hasRoleMOH_Directorate || hasRoleMOH_HGG || hasRoleSYGM_EPED || hasRoleSYGM_BR || hasRoleSYGM_ID || 
          hasRoleMOH_PD || hasRoleTUMAS_TeamLeader || hasRoleTUMAS_RegionalDirector || hasRoleTUMAS_Design
          || hasRoleSUMAF_Team || hasRoleEUD) &&
          <Route
            path='/site-management/ptr/*'
            element={
              <SuspensedView>
                <SitesPagePTR />
              </SuspensedView>
            }
          />
        }

        {(hasRoleSuperadmin || hasRoleCEB || hasRoleMOH_PIU || hasRoleMOH_Directorate || hasRoleMOH_HGG || hasRoleSYGM_EPED || hasRoleSYGM_BR || hasRoleSYGM_ID || 
          hasRoleMOH_PD || hasRoleTUMAS_TeamLeader || hasRoleTUMAS_RegionalDirector || hasRoleTUMAS_Design
          || hasRoleSUMAF_Team || hasRoleEUD) &&
          <Route
            path='/site-management/hospital/*'
            element={
              <SuspensedView>
                <SitesPageHospital />
              </SuspensedView>
            }
          />
        }

        {(hasRoleSuperadmin || hasRoleMOH_PIU || hasRoleMOH_Directorate || hasRoleSYGM_EPED ||
          hasRoleMOH_PD || hasRoleTUMAS_TeamLeader || hasRoleTUMAS_Design || hasRoleTUMAS_RegionalDirector) &&
          <Route
            path='/mhc-management/checklist-management/*'
            element={
              <SuspensedView>
                <ChecklistPage />
              </SuspensedView>
            }
          />
        }

        {(hasRoleSuperadmin || hasRoleCEB || hasRoleMOH_PIU || hasRoleMOH_Directorate || hasRoleMOH_HGG || hasRoleSYGM_EPED || hasRoleSYGM_BR || hasRoleSYGM_ID || 
          hasRoleMOH_PD || hasRoleTUMAS_TeamLeader || hasRoleTUMAS_RegionalDirector || hasRoleTUMAS_Design
          || hasRoleSUMAF_Team || hasRoleEUD) &&
          <Route
            path='/mhc-management/checklist-monitoring/*'
            element={
              <SuspensedView>
                <ChecklistMonitoringPage />
              </SuspensedView>
            }
          />
        }

        {(hasRoleSuperadmin || hasRoleCEB || hasRoleMOH_PIU || hasRoleMOH_Directorate || hasRoleMOH_HGG || hasRoleSYGM_EPED || hasRoleSYGM_BR || hasRoleSYGM_ID || 
          hasRoleMOH_PD || hasRoleTUMAS_TeamLeader || hasRoleTUMAS_RegionalDirector || hasRoleTUMAS_Design
          || hasRoleSUMAF_Team || hasRoleEUD) &&
          <Route
            path='/mhc-management/ptr-list-monitoring/*'
            element={
              <SuspensedView>
                <PTRListPage />
              </SuspensedView>
            }
          />
        }

        {(hasRoleSuperadmin || hasRoleCEB || hasRoleMOH_PIU || hasRoleMOH_Directorate || hasRoleMOH_HGG || hasRoleSYGM_EPED || hasRoleSYGM_BR || hasRoleSYGM_ID || 
          hasRoleMOH_PD || hasRoleTUMAS_TeamLeader || hasRoleTUMAS_RegionalDirector || hasRoleTUMAS_Design
          || hasRoleSUMAF_Team || hasRoleEUD) &&
          <Route
            path='/mhc-management/emhc-list-monitoring/*'
            element={
              <SuspensedView>
                <EMHCListPage />
              </SuspensedView>
            }
          />
        }

        {(hasRoleSuperadmin || hasRoleCEB || hasRoleMOH_PIU || hasRoleMOH_Directorate || hasRoleMOH_HGG || hasRoleSYGM_EPED || hasRoleSYGM_BR || hasRoleSYGM_ID || 
          hasRoleMOH_PD || hasRoleTUMAS_TeamLeader || hasRoleTUMAS_RegionalDirector || hasRoleTUMAS_Design
          || hasRoleSUMAF_Team || hasRoleEUD) &&
          <Route
            path='/mhc-management/hospital-rehabilitation-list-monitoring/*'
            element={
              <SuspensedView>
                <RehabListPage />
              </SuspensedView>
            }
          />
        }

        {(hasRoleSuperadmin || hasRoleCEB || hasRoleMOH_PIU || hasRoleMOH_Directorate
        || hasRoleSUMAF_Team || hasRoleEUD) &&
          <Route
            path='/users/*'
            element={
              <SuspensedView>
                <UsersPageCEB />
              </SuspensedView>
            }
          />
        }

        {/* {hasRoleAuthority &&
          <Route
            path='/users/*'
            element={
              <SuspensedView>
                <UsersPageAuthority />
              </SuspensedView>
            }
          />
        }
        {(hasRoleVendor && isAdmin === "True") &&
          <Route
            path='/users/*'
            element={
              <SuspensedView>
                <UsersPageVendor />
              </SuspensedView>
            }
          />
        } */}

        {(hasRoleSuperadmin || hasRoleMOH_PIU || hasRoleMOH_Directorate || hasRoleSYGM_EPED  ||
          hasRoleMOH_PD || hasRoleTUMAS_TeamLeader || hasRoleTUMAS_Design || hasRoleTUMAS_RegionalDirector) &&
          <Route
            path='/mhc-management/ptr-checklist/*'
            element={
              <SuspensedView>
                <PtrManagementPage />
              </SuspensedView>
            }
          />
        }

        {(hasRoleSuperadmin || hasRoleMOH_PIU || hasRoleMOH_Directorate || hasRoleSYGM_EPED  ||
          hasRoleMOH_PD || hasRoleTUMAS_TeamLeader || hasRoleTUMAS_Design || hasRoleTUMAS_RegionalDirector) &&
          <Route
            path='/mhc-management/hospital-rehabilitation-list/*'
            element={
              <SuspensedView>
                <RehabHospitalManagementPage />
              </SuspensedView>
            }
          />
        }

        {(hasRoleSuperadmin || hasRoleMOH_PIU || hasRoleMOH_Directorate || hasRoleSYGM_EPED  ||
          hasRoleMOH_PD || hasRoleTUMAS_TeamLeader || hasRoleTUMAS_Design || hasRoleTUMAS_RegionalDirector) &&
          <Route
            path='/mhc-management/emhc-list/*'
            element={
              <SuspensedView>
                <EMHCManagementPage />
              </SuspensedView>
            }
          />
        }

        {(hasRoleSuperadmin || hasRoleCEB || hasRoleMOH_PIU || hasRoleMOH_Directorate || hasRoleMOH_HGG || hasRoleSYGM_BR || hasRoleSYGM_EPED || hasRoleSYGM_ID ||
          hasRoleMOH_PD || hasRoleTUMAS_TeamLeader || hasRoleTUMAS_RegionalDirector
          || hasRoleSUMAF_Team || hasRoleEUD) &&
          <Route
            path='/progress-payment-management/civil/*'
            element={
              <SuspensedView>
                <ProgressPaymentPage />
              </SuspensedView>
            }
          />
        }

        {(hasRoleSuperadmin || hasRoleCEB || hasRoleMOH_PIU || hasRoleMOH_Directorate || hasRoleMOH_HGG || hasRoleSYGM_BR || hasRoleSYGM_EPED || hasRoleSYGM_ID ||
          hasRoleMOH_PD || hasRoleTUMAS_TeamLeader || hasRoleTUMAS_RegionalDirector
          || hasRoleSUMAF_Team || hasRoleEUD) &&
          <Route
            path='/progress-payment-management/civilBarchart/*'
            element={
              <SuspensedView>
                <ProgressPaymentBarchartPage />
              </SuspensedView>
            }
          />
        }

        {(hasRoleSuperadmin || hasRoleCEB || hasRoleMOH_PIU || hasRoleMOH_Directorate || hasRoleMOH_HGG || hasRoleSYGM_BR || hasRoleSYGM_EPED || hasRoleSYGM_ID ||
          hasRoleMOH_PD || hasRoleTUMAS_TeamLeader || hasRoleTUMAS_RegionalDirector
          || hasRoleSUMAF_Team || hasRoleEUD) &&
          <Route
            path='/progress-payment-management/serviceandsupply/*'
            element={
              <SuspensedView>
                <ProgressPaymentPageForSS />
              </SuspensedView>
            }
          />
        }

        <Route
          path='/files-upload/*'
          element={
            <SuspensedView>
              <FileUploadPage />
            </SuspensedView>
          }
        />

        {(hasRoleSuperadmin || hasRoleCEB || hasRoleEUD || hasRoleMOH_PIU /*|| hasRoleMOH_HGG || hasRoleSYGM_BR || hasRoleSYGM_EPED || hasRoleSYGM_ID ||
          hasRoleKHGM_IB || hasRoleHSGM_IB || hasRoleHSGM_GS
          || hasRoleSUMAF_Team*/) &&
          <Route
            path='/procurement-management/*'
            element={
              <SuspensedView>
                <ProcurementPage />
              </SuspensedView>
            }
          />
        }

        <Route
          path='/files-manager/*'
          element={
            <SuspensedView>
              <FileControl />
            </SuspensedView>
          }
        />

        {(hasRoleSuperadmin || hasRoleCEB || hasRoleMOH_PIU || hasRoleMOH_Directorate || hasRoleMOH_HGG || hasRoleSYGM_BR || hasRoleSYGM_EPED || hasRoleSYGM_ID ||
          hasRoleMOH_PD || hasRoleTUMAS_TeamLeader || hasRoleTUMAS_RegionalDirector || hasRoleTUMAS_Supervisor
          || hasRoleSUMAF_Team || hasRoleEUD) &&
          <Route
            path='/rfi-management/*'
            element={
              <SuspensedView>
                <RFIsPage />
              </SuspensedView>
            }
          />
        }

        {(hasRoleSuperadmin || hasRoleCEB || hasRoleMOH_PIU || hasRoleMOH_Directorate || hasRoleMOH_HGG || hasRoleSYGM_BR || hasRoleSYGM_EPED || hasRoleSYGM_ID ||
          hasRoleMOH_PD || hasRoleTUMAS_TeamLeader || hasRoleTUMAS_RegionalDirector || hasRoleTUMAS_Supervisor
          || hasRoleSUMAF_Team || hasRoleEUD) &&
          <Route
            path='/ncr-management/*'
            element={
              <SuspensedView>
                <NCRsPage />
              </SuspensedView>
            }
          />
        }

        {(hasRoleSuperadmin || hasRoleCEB || hasRoleMOH_PIU || hasRoleMOH_Directorate || hasRoleMOH_HGG || hasRoleSYGM_BR || hasRoleSYGM_EPED || hasRoleSYGM_ID ||
          hasRoleMOH_PD || hasRoleTUMAS_TeamLeader || hasRoleTUMAS_RegionalDirector || hasRoleTUMAS_Supervisor
          || hasRoleSUMAF_Team || hasRoleEUD) &&
          <Route
            path='/maf-management/*'
            element={
              <SuspensedView>
                <MAFsPage />
              </SuspensedView>
            }
          />
        }

        {(hasRoleSuperadmin || hasRoleCEB || hasRoleMOH_PIU || hasRoleMOH_Directorate || hasRoleMOH_HGG || hasRoleSYGM_BR || hasRoleSYGM_EPED || hasRoleSYGM_ID ||
          hasRoleMOH_PD || hasRoleTUMAS_TeamLeader || hasRoleTUMAS_RegionalDirector || hasRoleTUMAS_Supervisor
          || hasRoleSUMAF_Team || hasRoleEUD) &&
          <Route
            path='/letter-management/*'
            element={
              <SuspensedView>
                <LettersPage />
              </SuspensedView>
            }
          />
        }

        {(hasRoleSuperadmin || hasRoleCEB || hasRoleMOH_PIU || hasRoleMOH_Directorate || hasRoleMOH_HGG || hasRoleSYGM_BR || hasRoleSYGM_EPED || hasRoleSYGM_ID ||
          hasRoleMOH_PD || hasRoleTUMAS_TeamLeader || hasRoleTUMAS_RegionalDirector || hasRoleTUMAS_Supervisor
          || hasRoleSUMAF_Team || hasRoleEUD) &&
          <Route
            path='/meeting-note-management/*'
            element={
              <SuspensedView>
                <MNsPage />
              </SuspensedView>
            }
          />
        }
        {(hasRoleSuperadmin || hasRoleCEB || hasRoleMOH_PIU || hasRoleMOH_Directorate || hasRoleMOH_HGG || hasRoleSYGM_BR || hasRoleSYGM_EPED || hasRoleSYGM_ID ||
          hasRoleMOH_PD || hasRoleTUMAS_TeamLeader || hasRoleTUMAS_RegionalDirector || hasRoleTUMAS_Supervisor
          || hasRoleSUMAF_Team || hasRoleEUD) &&
          <Route
            path='/other-management/*'
            element={
              <SuspensedView>
                <OthersPage />
              </SuspensedView>
            }
          />
        }

        {(hasRoleSuperadmin || hasRoleCEB || hasRoleMOH_PIU || hasRoleMOH_Directorate || hasRoleMOH_HGG || hasRoleSYGM_BR || hasRoleSYGM_EPED || hasRoleSYGM_ID ||
          hasRoleMOH_PD || hasRoleTUMAS_TeamLeader || hasRoleTUMAS_RegionalDirector
          || hasRoleSUMAF_Team || hasRoleEUD) &&
          <Route
            path='/variation-order-management/*'
            element={
              <SuspensedView>
                <VariationOrdersPage />
              </SuspensedView>
            }
          />
        }

        {(hasRoleSuperadmin || hasRoleCEB || hasRoleMOH_PIU || hasRoleMOH_Directorate || hasRoleMOH_HGG || hasRoleSYGM_BR || hasRoleSYGM_EPED || hasRoleSYGM_ID ||
          hasRoleMOH_PD || hasRoleTUMAS_TeamLeader || hasRoleTUMAS_RegionalDirector || hasRoleTUMAS_Supervisor
          || hasRoleSUMAF_Team || hasRoleEUD) &&
          <Route
            path='/construction-reports-management/*'
            element={
              <SuspensedView>
                <ConstructionReportsPage />
              </SuspensedView>
            }
          />
        }

        {(hasRoleSuperadmin || hasRoleCEB || hasRoleMOH_PIU || hasRoleMOH_Directorate || hasRoleMOH_HGG || hasRoleSYGM_BR || hasRoleSYGM_EPED || hasRoleSYGM_ID ||
          hasRoleHSGM_IB || hasRoleHSGM_GS || hasRoleKHGM_IB || hasRoleMOH_PD || hasRoleTUMAS_TeamLeader || hasRoleTUMAS_RegionalDirector
          || hasRoleSUMAF_Team || hasRoleEUD) &&
          <Route
            path='/completion-reports-management/*'
            element={
              <SuspensedView>
                <CompletionReportsPage />
              </SuspensedView>
            }
          />
        }

        {(hasRoleSuperadmin || hasRoleCEB || hasRoleMOH_PIU || hasRoleMOH_Directorate || hasRoleMOH_HGG || hasRoleSYGM_BR || hasRoleSYGM_EPED || hasRoleSYGM_ID ||
          hasRoleHSGM_IB || hasRoleHSGM_GS || hasRoleKHGM_IB || hasRoleMOH_PD || hasRoleTUMAS_TeamLeader || hasRoleTUMAS_RegionalDirector
          || hasRoleSUMAF_Team || hasRoleEUD) &&
          <Route
            path='/building-handover-reports-management/*'
            element={
              <SuspensedView>
                <BuildingHandoverReportsPage />
              </SuspensedView>
            }
          />
        }

        {(hasRoleSuperadmin || hasRoleCEB || hasRoleMOH_PIU || hasRoleMOH_Directorate || hasRoleMOH_HGG || hasRoleSYGM_BR || hasRoleSYGM_EPED || hasRoleSYGM_ID ||
          hasRoleHSGM_IB || hasRoleHSGM_GS || hasRoleKHGM_IB || hasRoleMOH_PD || hasRoleTUMAS_TeamLeader || hasRoleTUMAS_RegionalDirector
          || hasRoleSUMAF_Team || hasRoleEUD) &&
          <Route
            path='/dnp-reports-management/*'
            element={
              <SuspensedView>
                <DNPReportsPage />
              </SuspensedView>
            }
          />
        }

        {(hasRoleSuperadmin || hasRoleCEB || hasRoleMOH_PIU || hasRoleMOH_Directorate || hasRoleMOH_HGG || hasRoleSYGM_BR || hasRoleSYGM_EPED || hasRoleSYGM_ID ||
          hasRoleHSGM_IB || hasRoleHSGM_GS || hasRoleKHGM_IB || hasRoleMOH_PD || hasRoleTUMAS_TeamLeader || hasRoleTUMAS_RegionalDirector
          || hasRoleSUMAF_Team || hasRoleEUD) &&
          <Route
            path='/legal-reports-management/*'
            element={
              <SuspensedView>
                <LegalReportsPage />
              </SuspensedView>
            }
          />
        }

        {(hasRoleSuperadmin || hasRoleCEB || hasRoleMOH_PIU || hasRoleMOH_Directorate || hasRoleMOH_HGG || hasRoleSYGM_BR || hasRoleSYGM_EPED || hasRoleSYGM_ID ||
          hasRoleHSGM_IB || hasRoleHSGM_GS || hasRoleKHGM_IB || hasRoleMOH_PD || hasRoleTUMAS_TeamLeader || hasRoleTUMAS_RegionalDirector
          || hasRoleSUMAF_Team || hasRoleEUD) &&
          <Route
            path='/abd-reports-management/*'
            element={
              <SuspensedView>
                <AbdReportsPage />
              </SuspensedView>
            }
          />
        }

        {(hasRoleSuperadmin || hasRoleCEB || hasRoleMOH_PIU || hasRoleMOH_Directorate || hasRoleMOH_HGG || hasRoleSYGM_BR || hasRoleSYGM_EPED || hasRoleSYGM_ID ||
          hasRoleHSGM_IB || hasRoleHSGM_GS || hasRoleKHGM_IB || hasRoleMOH_PD || hasRoleTUMAS_TeamLeader || hasRoleTUMAS_RegionalDirector
          || hasRoleSUMAF_Team || hasRoleEUD) &&
          <Route
            path='/facility-reports-management/*'
            element={
              <SuspensedView>
                <FacilityReportsPage />
              </SuspensedView>
            }
          />
        }

        <Route
          path='/bug-reporting/*'
          element={
            <SuspensedView>
              <BugReportingPage />
            </SuspensedView>
          }
        />

        <Route
          path='/log-frame/*'
          element={
            <SuspensedView>
              <LogFramePage />
            </SuspensedView>
          }
        />
        
        <Route
          path='/risk-matrix/*'
          element={
            <SuspensedView>
              <RiskMatrixPage />
            </SuspensedView>
          }
        />

        <Route
          path='/shifa-implementation/*'
          element={
            <SuspensedView>
              <ShifaImplementationPage />
            </SuspensedView>
          }
        />

        <Route
          path='/financial-overview/*'
          element={
            <SuspensedView>
              <FinancialOverviewPage />
            </SuspensedView>
          }
        />

        <Route
          path='/estimated-cost/*'
          element={
            <SuspensedView>
              <EstimatedCostPage />
            </SuspensedView>
          }
        />
        

        <Route
          path='/communication-strategy/*'
          element={
            <SuspensedView>
              <CommunicationStrategyPage />
            </SuspensedView>
          }
        />

        <Route
          path='/printed-material/*'
          element={
            <SuspensedView>
              <PrintedMaterialsPage />
            </SuspensedView>
          }
        />

        <Route
          path='/online-tool/*'
          element={
            <SuspensedView>
              <OnlineToolsPage />
            </SuspensedView>
          }
        />

        <Route
          path='/audiovisual-tool/*'
          element={
            <SuspensedView>
              <Audio_VisualToolsPage />
            </SuspensedView>
          }
        />

        <Route
          path='/information-campaign/*'
          element={
            <SuspensedView>
              <InformationCampaignPage />
            </SuspensedView>
          }
        />

        <Route
          path='/visibility-event/*'
          element={
            <SuspensedView>
              <VisibilityEventPage />
            </SuspensedView>
          }
        />

        <Route
          path='/contestsite-event/*'
          element={
            <SuspensedView>
              <Contest_SiteEventPage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
