import { MenuItem } from './MenuItem'
import { MenuInnerWithSub } from './MenuInnerWithSub'
import { useIntl } from 'react-intl'
import { getIsUserAdmin, getUserRole, getIsServiceVendor, getInstituionId, ROLES } from '../../../helpers'

export function MenuInner() {
  const intl = useIntl()
  const role = getUserRole();
  const institutionId = getInstituionId();
  const isAdmin = getIsUserAdmin();
  const isServiceVendor = getIsServiceVendor();

  return (
    <>
      {<MenuItem title={intl.formatMessage({ id: 'NAV.DASHBOARD' })} to='/dashboard' />}
      {<MenuItem title={intl.formatMessage({ id: 'NAV.MAP' })} to='/map-management' />}
      { (role !== ROLES.GOPA.name) &&
        <MenuInnerWithSub title={intl.formatMessage({ id: 'NAV.FILES' })} to='' menuPlacement='bottom-start' menuTrigger={`{default:'click', lg: 'hover'}`}>
        <MenuItem to='/files-manager/' hasBullet={true} title={intl.formatMessage({ id: 'NAV.FILE.MANAGEMENT' })} />
      </MenuInnerWithSub>
      }
      {(role === ROLES.Superadmin.name || role === ROLES.CEB.name || role === ROLES.MOH_PIU.name || role === ROLES.EUD.name 
      /** || role === ROLES.MOH_HGG.name
       || role === ROLES.SYGM_ID.name || role === ROLES.KHGM_IB.name || role === ROLES.HSGM_IB.name
       || role === ROLES.SUMAF_Team.name 
        */) &&
        <MenuInnerWithSub title={intl.formatMessage({ id: 'NAV.PROCUREMENT' })} to='procurement' menuPlacement='bottom-start' menuTrigger={`{default:'click', lg: 'hover'}`}>
          <MenuItem to='/procurement-management/' hasBullet={true} title={intl.formatMessage({ id: 'NAV.PROCUREMENT.MANAGEMENT' })} />
          <MenuItem to='/procurement-plan/' hasBullet={true} title={intl.formatMessage({ id: 'NAV.PROCUREMENT.PLAN' })} />
        </MenuInnerWithSub>
        //|| role === ROLES.SYGM_EPED.name
        //|| role === ROLES.SYGM_BR.name
      }

      {(role === ROLES.Superadmin.name || role === ROLES.CEB.name || role === ROLES.MOH_PIU.name || role === ROLES.MOH_Directorate.name || role === ROLES.MOH_HGG.name
       || role === ROLES.SYGM_BR.name || role === ROLES.SYGM_EPED.name || role === ROLES.SYGM_ID.name || role === ROLES.MOH_PD.name
       || role == ROLES.TUMAS_TeamLeader.name || role == ROLES.TUMAS_RegionalDirector.name || role == ROLES.TUMAS_Design.name
       || role === ROLES.SUMAF_Team.name || role === ROLES.EUD.name) &&
        <MenuInnerWithSub title={intl.formatMessage({ id: 'DESING.DOCUMENTS' })} to='/mhc-management' menuPlacement='bottom-start' menuTrigger={`{default:'click', lg: 'hover'}`} >
          <MenuInnerWithSub to={'/newconstruction'} title={intl.formatMessage({ id: 'NEW.CONSTRUCTIONS' })}
            hasBullet={true}
            hasArrow={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`} >
            <MenuItem
              icon='/media/icons/duotune/general/gen055.svg'
              to='/site-management/mhc'
              title={intl.formatMessage({ id: 'ADD.NEW.SITE.MHC' })}
              hasBullet={true}
            />
            {(role != ROLES.CEB.name && role != ROLES.MOH_HGG.name && role != ROLES.SYGM_ID.name && role != ROLES.SYGM_BR.name
              && role != ROLES.TUMAS_RegionalDirector && role != ROLES.SUMAF_Team.name && role != ROLES.EUD.name) &&
              <MenuItem
                icon='/media/icons/duotune/general/gen055.svg'
                to='/mhc-management/checklist-management'
                // title={intl.formatMessage({ id: 'CHECKLIST.MHC.MANAGEMENT' })}
                title={intl.formatMessage({ id: 'MANAGEMENT' })}
                hasBullet={true}
              />
            }
            <MenuItem
              icon='/media/icons/duotune/general/gen005.svg'
              to='/mhc-management/checklist-monitoring'
              // title={intl.formatMessage({ id: 'CHECKLIST.MHC.MONITORING' })}
              title={intl.formatMessage({ id: 'MONITORING' })}
              hasBullet={true}
            />
          </MenuInnerWithSub>
          <MenuInnerWithSub
            to={'/rehabilitation'}
            title={intl.formatMessage({ id: 'REHABILITATIONS' })}
            hasBullet={true}
            hasArrow={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}>
            <MenuInnerWithSub
              icon='/media/icons/duotune/general/gen055.svg'
              to='/mhc-management/emhc-list-monitoring'
              title={intl.formatMessage({ id: 'MHC.AND.EMHC.REHABILITATION' })}
              hasBullet={true}
              hasArrow={true}
              menuPlacement='right-start'
              menuTrigger={`{default:'click', lg: 'hover'}`}>
              <MenuItem
                icon='/media/icons/duotune/general/gen055.svg'
                to='/site-management/emhc'
                title={intl.formatMessage({ id: 'ADD.NEW.SITE.EMHC' })}
              />
              {(role != ROLES.CEB.name && role != ROLES.MOH_HGG.name && role != ROLES.SYGM_ID.name && role != ROLES.SYGM_BR.name
              && role != ROLES.TUMAS_RegionalDirector && role != ROLES.SUMAF_Team.name && role != ROLES.EUD.name) &&
                <MenuItem
                  icon='/media/icons/duotune/general/gen055.svg'
                  to='/mhc-management/emhc-list/'
                  title={intl.formatMessage({ id: 'MANAGEMENT' })}
                />
              }
              <MenuItem
                icon='/media/icons/duotune/general/gen005.svg'
                to='/mhc-management/emhc-list-monitoring'
                // title={intl.formatMessage({ id: 'CHECKLIST.EMHC.MONITORING' })}
                title={intl.formatMessage({ id: 'MONITORING' })}
              />
            </MenuInnerWithSub>
            <MenuInnerWithSub
              icon='/media/icons/duotune/general/gen055.svg'
              to='/mhc-management/hospital-rehabilitation'
              title={intl.formatMessage({ id: 'HOSPITAL.REHABILITATION' })}
              hasBullet={true}
              hasArrow={true}
              menuPlacement='right-start'
              menuTrigger={`{default:'click', lg: 'hover'}`}>
              <MenuItem
                icon='/media/icons/duotune/general/gen055.svg'
                to='/site-management/hospital'
                title={intl.formatMessage({ id: 'ADD.NEW.SITE.HOSPITAL' })}
              />
              {(role != ROLES.CEB.name && role != ROLES.MOH_HGG.name && role != ROLES.SYGM_ID.name && role != ROLES.SYGM_BR.name
              && role != ROLES.TUMAS_RegionalDirector && role != ROLES.SUMAF_Team.name && role != ROLES.EUD.name) &&
                <MenuItem
                  icon='/media/icons/duotune/general/gen055.svg'
                  to='/mhc-management/hospital-rehabilitation-list'
                  title={intl.formatMessage({ id: 'MANAGEMENT' })}
                />
              }
              <MenuItem
                icon='/media/icons/duotune/general/gen005.svg'
                to='/mhc-management/hospital-rehabilitation-list-monitoring'
                title={intl.formatMessage({ id: 'MONITORING' })}
              />
            </MenuInnerWithSub>
          </MenuInnerWithSub>
          <MenuInnerWithSub to={''}
            title={intl.formatMessage({ id: "PTR.RENOVATİONS" })}
            hasBullet={true}
            hasArrow={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}>
            <MenuItem
              icon='/media/icons/duotune/general/gen055.svg'
              to='/site-management/ptr'
              title={intl.formatMessage({ id: 'ADD.NEW.SITE.PTR' })}
              hasBullet={true}
            />
            {(role != ROLES.CEB.name && role != ROLES.MOH_HGG.name && role != ROLES.SYGM_ID.name && role != ROLES.SYGM_BR.name
              && role != ROLES.TUMAS_RegionalDirector && role != ROLES.SUMAF_Team.name && role != ROLES.EUD.name) &&
              <MenuItem
                icon='/media/icons/duotune/general/gen055.svg'
                to='/mhc-management/ptr-checklist'
                title={intl.formatMessage({ id: 'MANAGEMENT' })}
                hasBullet={true}
              />
            }
            <MenuItem
              icon='/media/icons/duotune/general/gen005.svg'
              to='/mhc-management/ptr-list-monitoring'
              // title={intl.formatMessage({ id: 'CHECKLIST.PTR.MONITORING' })}
              title={intl.formatMessage({ id: 'MONITORING' })}
              hasBullet={true}
            />
          </MenuInnerWithSub>
        </MenuInnerWithSub>
      }

      {
        (role === ROLES.Superadmin.name || role === ROLES.CEB.name || role === ROLES.MOH_PIU.name || role === ROLES.MOH_Directorate.name || role === ROLES.MOH_HGG.name
          || role === ROLES.SYGM_BR.name || role === ROLES.SYGM_EPED.name || role === ROLES.SYGM_ID.name || role === ROLES.MOH_PD.name
          || role == ROLES.TUMAS_TeamLeader.name || role == ROLES.TUMAS_RegionalDirector.name 
          || role === ROLES.SUMAF_Team.name || role === ROLES.EUD.name)
        && <MenuInnerWithSub title={intl.formatMessage({ id: 'CONTRACT.MANAGEMENT' })} to='' menuPlacement='bottom-start' menuTrigger={`{default:'click', lg: 'hover'}`}>
          <MenuInnerWithSub to={''} title={intl.formatMessage({ id: 'PROGRESS.PAYMENT.MANAGEMENT' })}
            hasBullet={true}
            hasArrow={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`} >
            <MenuItem
              icon='/media/icons/duotune/general/gen055.svg'
              to='/progress-payment-management/civil'
              title={intl.formatMessage({ id: 'ADD.NEW.CIVIL' })}
              hasBullet={true}
            />
            <MenuItem
              icon='/media/icons/duotune/general/gen055.svg'
              to='/progress-payment-management/civilBarchart'
              title={intl.formatMessage({ id: 'PROGRESSPAYMENT_GRAPH' })}
              hasBullet={true}
            />
            <MenuItem
              icon='/media/icons/duotune/general/gen055.svg'
              to='/progress-payment-management/serviceandsupply'
              title={intl.formatMessage({ id: 'ADD.NEW.SERVICE_SUPPLY' })}
              hasBullet={true}
            />
          </MenuInnerWithSub>
          <MenuItem to='variation-order-management' title={intl.formatMessage({ id: 'VARIATION.ORDERS' })} hasBullet={true} />
        </MenuInnerWithSub>}

      {(role === ROLES.Superadmin.name || role === ROLES.CEB.name || role === ROLES.MOH_PIU.name || role === ROLES.MOH_Directorate.name || role === ROLES.MOH_HGG.name
       || role === ROLES.SYGM_BR.name || role === ROLES.SYGM_EPED.name || role === ROLES.SYGM_ID.name || role === ROLES.MOH_PD.name
       || role == ROLES.TUMAS_TeamLeader.name || role == ROLES.TUMAS_RegionalDirector.name || role == ROLES.TUMAS_Supervisor.name
       || role === ROLES.SUMAF_Team.name || role === ROLES.EUD.name)
        && <MenuInnerWithSub title={intl.formatMessage({ id: 'SUPERVISION' })} to='' menuPlacement='bottom-start' menuTrigger={`{default:'click', lg: 'hover'}`}>
          <MenuItem to='maf-management' title={intl.formatMessage({ id: 'MAF' })} hasBullet={true} />
          <MenuItem to='rfi-management' title={intl.formatMessage({ id: 'RFI' })} hasBullet={true} />
          <MenuItem to='ncr-management' title={intl.formatMessage({ id: 'NCR' })} hasBullet={true} />
          <MenuItem to='construction-reports-management' title={intl.formatMessage({ id: 'CR' })} hasBullet={true} />
          <MenuInnerWithSub
            to={'/rehabilitation'}
            // title={intl.formatMessage({ id: 'REHABILITATIONS' })}
            title={intl.formatMessage({ id: 'CONSTRUCTION.DOCS' })}
            hasBullet={true}
            hasArrow={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}>
            <MenuItem to='letter-management' title={intl.formatMessage({ id: 'LETTERS.COMM' })} hasBullet={true} />
            <MenuItem to='meeting-note-management' title={intl.formatMessage({ id: 'MEETING.NOTES' })} hasBullet={true} />
            <MenuItem to='other-management' title={intl.formatMessage({ id: 'OTHER' })} hasBullet={true} />
          </MenuInnerWithSub>
        </MenuInnerWithSub>}
      
      {(role === ROLES.Superadmin.name || role === ROLES.CEB.name || role === ROLES.MOH_PIU.name || role === ROLES.MOH_Directorate.name || role === ROLES.MOH_HGG.name
       || role === ROLES.SYGM_BR.name || role === ROLES.SYGM_EPED.name || role === ROLES.SYGM_ID.name || role === ROLES.KHGM_IB.name || role === ROLES.HSGM_IB.name
       || role === ROLES.SUMAF_Team.name || role === ROLES.EUD.name || role === ROLES.HSGM_GS.name)
        && <MenuItem to='/stakeholders-management/' title={intl.formatMessage({ id: 'STAKEHOLDER.MANAGEMENT' })} />
      }

      {(role === ROLES.Superadmin.name || role === ROLES.CEB.name || role === ROLES.MOH_PIU.name || role === ROLES.MOH_Directorate.name
      || role === ROLES.SUMAF_Team.name || role === ROLES.EUD.name)
        && <MenuItem to='/users/' title={intl.formatMessage({ id: 'NAV.USERS' })} />
      }

      <MenuInnerWithSub title={intl.formatMessage({ id: 'PROJECT.TOOLS' })} to='' menuPlacement='bottom-start' menuTrigger={`{default:'click', lg: 'hover'}`}>
        <MenuItem to='log-frame' title={intl.formatMessage({ id: 'LOG.FRAME' })} hasBullet={true} />
        <MenuItem to='risk-matrix' title={intl.formatMessage({ id: 'RISK.MATRIX' })} hasBullet={true} />
        { (role === ROLES.Superadmin.name || role === ROLES.CEB.name) &&
          <MenuItem to='shifa-implementation' title={intl.formatMessage({ id: 'SHIFA.IMPLEMENTATION.TABLE' })} hasBullet={true} />
        }
        { (role === ROLES.Superadmin.name || role === ROLES.CEB.name) &&
          <MenuItem to='financial-overview' title={intl.formatMessage({ id: 'FINANCIAL.OVERVIEW' })} hasBullet={true} />
        }
        { (role === ROLES.Superadmin.name ) &&
          <MenuItem to='estimated-cost' title={intl.formatMessage({ id: 'ESTIMATED.COST' })} hasBullet={true} />
        }
      </MenuInnerWithSub>

      
      

      <MenuInnerWithSub title={intl.formatMessage({ id: 'VIS.COMM' })} to='' menuPlacement='bottom-start' menuTrigger={`{default:'click', lg: 'hover'}`}>
        <MenuItem to='communication-strategy' title={intl.formatMessage({ id: 'COMMUNICATION.STRATEGY' })} hasBullet={true} />
        <MenuItem to='printed-material' title={intl.formatMessage({ id: 'PRINTED.MATERIALS' })} hasBullet={true} />
        <MenuItem to='online-tool' title={intl.formatMessage({ id: 'ONLINE.TOOLS' })} hasBullet={true} />
        <MenuItem to='audiovisual-tool' title={intl.formatMessage({ id: 'AUDIO_VISUAL.TOOLS' })} hasBullet={true} />
        <MenuItem to='information-campaign' title={intl.formatMessage({ id: 'INFORMATION.CAMPAIGN' })} hasBullet={true} />
        <MenuItem to='visibility-event' title={intl.formatMessage({ id: 'VISIBILITY.EVENTS' })} hasBullet={true} />
        <MenuItem to='contestsite-event' title={intl.formatMessage({ id: 'CONTESTS_SITE.EVENTS' })} hasBullet={true} />
      </MenuInnerWithSub>

      {(role === ROLES.Superadmin.name || role === ROLES.CEB.name || role === ROLES.MOH_PIU.name || role === ROLES.MOH_Directorate.name || role === ROLES.MOH_HGG.name
          || role === ROLES.SYGM_BR.name || role === ROLES.SYGM_EPED.name || role === ROLES.SYGM_ID.name || role === ROLES.KHGM_IB.name || role === ROLES.HSGM_IB.name
          || role === ROLES.MOH_PD.name || role == ROLES.TUMAS_TeamLeader.name || role == ROLES.TUMAS_RegionalDirector.name
          || role === ROLES.SUMAF_Team.name || role === ROLES.EUD.name || role === ROLES.HSGM_GS.name) &&
        <MenuInnerWithSub title={intl.formatMessage({ id: 'CLOSE.OUT' })} to='' menuPlacement='bottom-start' menuTrigger={`{default:'click', lg: 'hover'}`}>
          <MenuItem to='completion-reports-management' title={intl.formatMessage({ id: 'COMPLETION.DOCUMENTS' })} hasBullet={true} />
          <MenuItem to='building-handover-reports-management' title={intl.formatMessage({ id: 'BUILDING.HANDOVER.DOCUMENTS' })} hasBullet={true} />
          <MenuItem to='dnp-reports-management' title={intl.formatMessage({ id: 'DNP.DOCUMENTS' })} hasBullet={true} />
          <MenuItem to='legal-reports-management' title={intl.formatMessage({ id: 'LEGAL.DOCUMENTS' })} hasBullet={true} />
          <MenuItem to='abd-reports-management' title={intl.formatMessage({ id: 'ABD.DOCUMENTS' })} hasBullet={true} />
          <MenuItem to='facility-reports-management' title={intl.formatMessage({ id: 'FACILITY.DOCUMENTS' })} hasBullet={true} />
        </MenuInnerWithSub>
      }

      {(role === ROLES.Superadmin.name || role === ROLES.MOH_PIU.name || role === ROLES.SUMAF_Team.name || role === ROLES.EUD.name) && <MenuItem to='/parameter-management/' title={intl.formatMessage({ id: 'PARAMETER.MANAGEMENT' })} />}
    </>
  )
}
